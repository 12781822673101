* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Quicksand';
}

body {
  background-color: #68ceff;
}

.ReactModal__Overlay {
  z-index: 5;
}
